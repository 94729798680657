<div class="row compareText">
  <mde-popover #appPopover1="mdePopover" [mdePopoverOverlapTrigger]="false" class="tmpopover">
    <mat-card class="popover-content">
      <mat-card-content *ngIf="!isFinancialCheckType" [innerHtml]="getPopoverContent(leftContainer)"></mat-card-content>
    </mat-card>
  </mde-popover>
  <mde-popover #appPopover2="mdePopover" [mdePopoverOverlapTrigger]="false" class="tmpopover">
    <mat-card class="popover-content">
      <mat-card-content *ngIf="!isMonolingualSearch" [innerHtml]="getPopoverContent(rightContainer)"></mat-card-content>
    </mat-card>
  </mde-popover>
  <div class="compareTextLeft" *ngIf="!showRegionCanvas && !isSquareBracketCheckType" mdePopoverTarget #appElement="mdePopoverTarget">
    <span
      *ngIf="!isContainerLocked(leftContainer) || (isFinancialCheckType && !leftAnnotationClicked)"
      [mdePopoverTriggerFor]="appPopover1">
      {{
        isFinancialCheckType && leftPdfViewer
          ? fileCompareService.formatFinCheckTotal(leftPdfViewer.financialCheckTotal, leftPdfViewer.hasNonPercentageValues)
          : (leftContainer | currentIndexValue)
      }}
    </span>
  </div>
  <div
    class="compareTextRight"
    *ngIf="!showRegionCanvas && !isMonolingualSearch && !isSquareBracketCheckType"
    mdePopoverTarget
    #appElement="mdePopoverTarget">
    <span *ngIf="!isContainerLocked(rightContainer) && !rightAnnotationClicked" [mdePopoverTriggerFor]="appPopover2">
      {{ rightContainer | currentIndexValue }}
    </span>
  </div>
  <div
    class="ignoreRegionButtons"
    *ngIf="showRegionCanvas"
    (window:keyup.enter)="saveRegions()"
    (window:keyup.esc)="cancelRegionsButtonClicked()">
    <mat-slide-toggle
      [attr.data-dd-action-name]="appConstants.CompareRegionLabel"
      *ngIf="!isFinancialCheckType"
      #compareRegion
      labelPosition="before"
      color="primary"
      (change)="selectIgnoreRegionType(!compareRegion.checked)"
      [(ngModel)]="!isIgnoreRegionSelected">
      {{ appConstants.CompareRegionLabel }}
    </mat-slide-toggle>
    <mat-slide-toggle
      [attr.data-dd-action-name]="appConstants.IgnoreRegionLabel"
      *ngIf="!isFinancialCheckType"
      #ignoreRegion
      labelPosition="before"
      color="primary"
      (change)="selectIgnoreRegionType(ignoreRegion.checked)"
      [(ngModel)]="isIgnoreRegionSelected">
      {{ appConstants.IgnoreRegionLabel }}
    </mat-slide-toggle>
    <app-switch-field
      [matTooltip]="regionToggleControl.value ? appConstants.SetForAllPagesToolTip : appConstants.SetForCurrentPagesToolTip"
      class="toggle-switch"
      [control]="regionToggleControl"
      onLabel="On"
      offLabel="Off"
      (valueChange)="updateCurrentPageRegions()">
    </app-switch-field>
    <button
      [attr.data-dd-action-name]="appConstants.SaveTooltip"
      mat-flat-button
      *ngIf="!isFinancialCheckType"
      (click)="saveRegions()"
      aria-label="Save">
      {{ appConstants.SaveTooltip }}
    </button>
    <button
      [attr.data-dd-action-name]="appConstants.CancelButtonLabel"
      mat-raised-button
      *ngIf="!isFinancialCheckType"
      (click)="cancelRegionsButtonClicked()"
      aria-label="Cancel">
      {{ appConstants.CancelButtonLabel }}
    </button>
  </div>
</div>

<!-- Header Start -->
<mat-toolbar>
  <button
    [attr.data-dd-action-name]="appConstants.DashboardTooltip"
    mat-icon-button
    (click)="loadDashboard()"
    onclick="this.blur()"
    [matTooltip]="appConstants.DashboardTooltip"
    aria-label="dashboard">
    <mat-icon>dashboard</mat-icon>
  </button>
  <mat-divider vertical></mat-divider>
  <button
    [attr.data-dd-action-name]="appConstants.AcceptTooltip"
    *ngIf="!showRegionCanvas && !isFinancialCheckType"
    mat-icon-button
    (click)="markAccept(leftContainer, rightContainer, isMonolingualSearch ? frameType.Left : frameType.Both, true)"
    [disabled]="anyContainerLocked() || showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.AcceptTooltip"
    aria-label="Mark as Accepted on both side">
    <mat-icon>done_all</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.ErrorTooltip"
    *ngIf="!showRegionCanvas && !isFinancialCheckType"
    mat-icon-button
    (click)="markReject(leftContainer, rightContainer, isMonolingualSearch ? frameType.Left : frameType.Both, true)"
    [disabled]="anyContainerLocked() || showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.ErrorTooltip"
    aria-label="Mark as Rejected on both side">
    <mat-icon>close</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.UndoLastAction"
    *ngIf="!showRegionCanvas && !isFinancialCheckType"
    mat-icon-button
    (click)="undoLastAction(leftContainer, rightContainer)"
    onclick="this.blur()"
    [disabled]="!hasUndoHistory() || showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
    [matTooltip]="appConstants.UndoLastAction"
    aria-label="undo last action">
    <mat-icon>undo</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.SaveTooltip"
    *ngIf="!showRegionCanvas"
    mat-icon-button
    (click)="saveComparePage()"
    [disabled]="areBothContainerLocked() || showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.SaveTooltip"
    aria-label="Save compare page">
    <mat-icon>save</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.GoToPage"
    *ngIf="!showRegionCanvas && !isMonolingualSearch && !isFinancialCheckType && !isSquareBracketSingleFile"
    mat-icon-button
    (click)="goToPage(leftContainer.currentPageNumber, rightContainer.currentPageNumber)"
    onclick="this.blur()"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked"
    [matTooltip]="appConstants.GoToPage"
    aria-label="Go to page">
    <mat-icon>double_arrow</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="isSquareBracketCheckType ? appConstants.SquareBracketCountPages : appConstants.RedactedDifferentCountPages"
    mat-icon-button
    (click)="goToDifferentPageCount()"
    onclick="this.blur()"
    *ngIf="(isCallRedaction && !showRegionCanvas && !isMonolingualSearch && !isFinancialCheckType) || isSquareBracketCheckType"
    [matTooltip]="isSquareBracketCheckType ? appConstants.SquareBracketCountPages : appConstants.RedactedDifferentCountPages"
    [disabled]="leftAnnotationClicked || rightAnnotationClicked">
    <mat-icon>pivot_table_chart</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.CompanySpecificGlossaryPagesTitle"
    mat-icon-button
    (click)="goToNextGlossaryPage()"
    *ngIf="isMonolingualSearch && isChineseCharactersConversionCheck && !isFinancialCheckType"
    onclick="this.blur()"
    aria-label="Go to next company-specific glossaries page"
    [matTooltip]="appConstants.CompanySpecificGlossaryPagesTitle"
    [disabled]="leftAnnotationClicked">
    <mat-icon>pivot_table_chart</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.AnnotatePdfTooltip"
    *ngIf="(isMonolingualSearch || isSquareBracketSingleFile) && !isFinancialCheckType"
    mat-icon-button
    (click)="annotationClick(frameType.Left)"
    onclick="this.blur()"
    [matTooltip]="appConstants.AnnotatePdfTooltip"
    aria-label="PDF Viewer"
    [disabled]="showRegionCanvas || leftAnnotationClicked || isJobLocked">
    <mat-icon svgIcon="pdf-viewer-white"></mat-icon>
  </button>
  <mat-divider vertical></mat-divider>
  <app-paginator
    [currentPageNumber]="leftContainer.currentPageNumber"
    [pageCount]="leftContainer.pageCount"
    (previous)="onPreviousClick(leftContainer)"
    (next)="onNextClick(leftContainer)"
    (navigate)="onNavigate(leftContainer, $event, false)"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked">
  </app-paginator>

  <span class="spacer"></span>

  <mat-divider vertical *ngIf="!isMonolingualSearch"></mat-divider>
  <app-paginator
    *ngIf="!isMonolingualSearch && !isFinancialCheckType && !isSquareBracketSingleFile"
    [currentPageNumber]="rightContainer.currentPageNumber"
    [pageCount]="rightContainer.pageCount"
    (previous)="onPreviousClick(rightContainer)"
    (next)="onNextClick(rightContainer)"
    (navigate)="onNavigate(rightContainer, $event, false)"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked">
  </app-paginator>
  <div class="sync-scroll" *ngIf="!isMonolingualSearch && !isFinancialCheckType && !isSquareBracketSingleFile">
    <mat-slide-toggle
      [attr.data-dd-action-name]="appConstants.SynchronousScrolingLabel"
      [(ngModel)]="synchronousScrollingMode"
      [matTooltip]="appConstants.SynchronousScrolingLabel"
      [disabled]="leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
      color="primary">
    </mat-slide-toggle>
  </div>
  <button
    [attr.data-dd-action-name]="appConstants.SetRegionTitle"
    mat-icon-button
    *ngIf="!isFinancialCheckType"
    (click)="setRegionButtonClicked()"
    onclick="this.blur()"
    [matTooltip]="appConstants.SetRegionTitle"
    aria-label="Ignore regions"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked">
    <mat-icon>crop_free</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="getBookmarkButtonTooltip()"
    mat-icon-button
    (click)="bookmarkPages()"
    onclick="this.blur()"
    [matTooltip]="getBookmarkButtonTooltip()"
    aria-label="Bookmark page"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || !jobInformation || isJobLocked">
    <mat-icon *ngIf="showBookmarkedIcon()" class="bookmark-solid"> bookmark </mat-icon>
    <mat-icon *ngIf="!showBookmarkedIcon()"> bookmark_border </mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.ViewSourcePdfTooltip"
    mat-icon-button
    (click)="viewSourcePdf()"
    onclick="this.blur()"
    [matTooltip]="appConstants.ViewSourcePdfTooltip"
    aria-label="View Source PDF"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked  || isViewSourcePdfDisabled">
    <mat-icon>picture_as_pdf</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.UnlockPageConfirmationTitle"
    mat-icon-button
    (click)="resetPageStatus(leftContainer, !isMonolingualSearch && !isSquareBracketSingleFile ? rightContainer : null)"
    [disabled]="!areBothContainerLocked() || showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked || isJobLocked"
    onclick="this.blur()"
    [matTooltip]="appConstants.UnlockPageConfirmationTitle"
    aria-label="Lock unlock compare page">
    <mat-icon>lock</mat-icon>
  </button>
  <button
    [attr.data-dd-action-name]="appConstants.JobInformationTitle"
    mat-icon-button
    (click)="viewJobInfo()"
    onclick="this.blur()"
    [disabled]="showRegionCanvas || leftAnnotationClicked || rightAnnotationClicked"
    [matTooltip]="appConstants.JobInformationTitle"
    aria-label="view job info">
    <mat-icon>info</mat-icon>
  </button>
</mat-toolbar>

<div [ngClass]="showRegionCanvas ? 'zoom75' : 'zoom'">
  <div class="container-fluid">
    <div [ngClass]="getIgnoreRegionClasses()">
      <div [ngClass]="isMonolingualSearch || isSquareBracketSingleFile ? 'iFrameContainer-monolingual' : 'col iFrameContainer'">
        <app-canvas
          #leftCanvas
          *ngIf="showRegionCanvas && !isFinancialCheckType"
          [regions]="regionToggleControl.value ? leftContainer.tmpFileRegions : leftContainer.tmpCurrentPageRegions"
          [height]="leftContainer.iFrameHeight"
          [width]="leftContainer.iFrameWidth"
          [toolbarPlacement]="1"
          [isIgnoreRegionSelected]="isIgnoreRegionSelected">
        </app-canvas>
        <app-vertical-toolbar
          #leftPageToolbar
          *ngIf="
            !showRegionCanvas &&
            !leftAnnotationClicked &&
            jobInformation &&
            !isMonolingualSearch &&
            !isFinancialCheckType &&
            !isSquareBracketSingleFile
          "
          [toolbarPlacement]="1"
          [acceptAndRejectDisabled]="anyContainerLocked()"
          [isBookmarked]="leftContainer.isBookmarked"
          [isPageLocked]="isContainerLocked(leftContainer)"
          [isJobLocked]="isJobLocked"
          [hasUndoHistory]="hasUndoHistory(leftContainer)"
          [hasSamePageCount]="hasSamePageCount"
          [hasNoFacts]="leftContainer.factList.length === 0"
          (accept)="onAcceptClick(frameType.Left)"
          (reject)="onRejectClick(frameType.Left)"
          (acceptAndReject)="onAcceptAndRejectClick(frameType.Left)"
          (swap)="onSwapClick(leftContainer)"
          (bookmarkPage)="bookmarkPage(leftContainer)"
          (resetPageStatus)="resetPageStatus(leftContainer)"
          (undo)="undoLastAction(leftContainer)"
          (annotate)="annotationClick(frameType.Left)">
        </app-vertical-toolbar>
        <iframe
          [hidden]="leftAnnotationClicked || isFinancialCheckType"
          id="left-doc"
          title="leftFrame"
          [ngClass]="showRegionCanvas ? 'iframe-ignoreRegion' : leftContainer.unSavedDocumentStyle">
        </iframe>
        <app-pdf-viewer
          #leftPdfViewer
          *ngIf="leftAnnotationClicked || (isFinancialCheckType && loadFinancialCheckViewer)"
          class="app-pdf-viewer"
          [jobIdentity]="jobIdentity"
          [pageIdentity]="pageIdentity"
          [pageSequence]="leftContainer.currentPageNumber"
          [leftFileIdentity]="leftContainer.fileId"
          [rightFileIdentity]="rightContainer.fileId"
          [leftdocument]="leftDocument"
          [accessToken]="accessToken"
          [monolingualSearch]="isMonolingualSearch"
          [isFinancialCheck]="isFinancialCheckType"
          [isPageLocked]="isPageLocked"
          pdfViewerSide="leftPdfViewer"
          (closeLeftPdfViewer)="closePdf(frameType.Left)"
          (changed)="OnChanged(leftContainer)"
          (showLoader)="showLoader($event)">
        </app-pdf-viewer>
      </div>
      <div
        class="col iFrameContainer"
        [hidden]="isMonolingualSearch || isFinancialCheckType || (isSquareBracketSingleFile && isSquareBracketCheckType)">
        <app-canvas
          #rightCanvas
          *ngIf="showRegionCanvas && !isMonolingualSearch && !isFinancialCheckType"
          [regions]="regionToggleControl.value ? rightContainer.tmpFileRegions : rightContainer.tmpCurrentPageRegions"
          [height]="rightContainer.iFrameHeight"
          [width]="rightContainer.iFrameWidth"
          [toolbarPlacement]="0"
          [isIgnoreRegionSelected]="isIgnoreRegionSelected">
        </app-canvas>
        <app-vertical-toolbar
          #rightPageToolbar
          *ngIf="!showRegionCanvas && !rightAnnotationClicked && jobInformation && !isMonolingualSearch && !isFinancialCheckType"
          [toolbarPlacement]="0"
          [acceptAndRejectDisabled]="anyContainerLocked()"
          [isBookmarked]="rightContainer.isBookmarked"
          [isPageLocked]="isContainerLocked(rightContainer)"
          [isJobLocked]="isJobLocked"
          [hasUndoHistory]="hasUndoHistory(rightContainer)"
          [hasSamePageCount]="hasSamePageCount"
          [hasNoFacts]="rightContainer.factList.length === 0"
          (accept)="onAcceptClick(frameType.Right)"
          (reject)="onRejectClick(frameType.Right)"
          (acceptAndReject)="onAcceptAndRejectClick(frameType.Right)"
          (swap)="onSwapClick(rightContainer)"
          (bookmarkPage)="bookmarkPage(rightContainer)"
          (resetPageStatus)="resetPageStatus(rightContainer)"
          (undo)="undoLastAction(rightContainer)"
          (annotate)="annotationClick(frameType.Right)">
        </app-vertical-toolbar>
        <iframe
          [hidden]="rightAnnotationClicked || isMonolingualSearch || isFinancialCheckType"
          id="right-doc"
          title="rightFrame"
          [ngClass]="showRegionCanvas ? 'iframe-ignoreRegion' : rightContainer.unSavedDocumentStyle">
        </iframe>
        <app-pdf-viewer
          *ngIf="rightAnnotationClicked"
          class="app-pdf-viewer"
          [jobIdentity]="jobIdentity"
          [pageIdentity]="pageIdentity"
          [pageSequence]="rightContainer.currentPageNumber"
          [rightFileIdentity]="rightContainer.fileId"
          [leftFileIdentity]="leftContainer.fileId"
          [rightdocument]="rightDocument"
          [accessToken]="accessToken"
          pdfViewerSide="rightPdfViewer"
          (closeRightPdfViewer)="closePdf(frameType.Right)"
          (showLoader)="showLoader($event)">
        </app-pdf-viewer>
      </div>
    </div>
  </div>
</div>
